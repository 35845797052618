/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// remove once phantomjs has support for Promises
//import "babel-polyfill";

// support older browsers
//require('core-js');
//require('core-js');
//console.log("running application.js");
//var jquery = require("expose-loader?jQuery!expose-loader?$!jquery")
var jquery = require('jquery');
window.$ = jquery
window.jQuery = jquery



import Rails from '@rails/ujs'

import swal from 'sweetalert2'
// replace rails confirmation window
const confirmed = (element, result) => {
  if (result) {
    // User clicked confirm button
    element.removeAttribute('data-confirm')
    element.click()
  }
}

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
  const message = element.getAttribute('data-confirm')
  const text = element.getAttribute('data-text')
  swal({
    title: message || 'Are you sure?',
    text: text || '',
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
  }).then(result => confirmed(element, result))
}

const allowAction = (element) => {
  if (element.getAttribute('data-confirm') === null) {
    return true
  }
  showConfirmationDialog(element)
  return false
}

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

Rails.delegate(document, 'a[data-confirm]', 'click', handleConfirm)
Rails.delegate(document, 'input[data-confirm]', 'click', handleConfirm)
Rails.start()


import "sweetalert2/dist/sweetalert2.css"
//require('jquery-ujs'); // rails unobtrusive scripting support
//import {} from 'jquery-ujs'

require("jquery-ui/ui/effect");
require("jquery-ui/ui/effects/effect-highlight");
//import "jquery"
// make jquery availble in linked js files


$.ajaxSetup ({
    // Disable caching of AJAX responses
    cache: false
});

// if ($.rails === undefined ) {
//   console.log('jquery-ujs not loaded!');
// } else {
//   console.log('jquery-ujs loaded!');
// }

//console.log(Rails);


require("../js/jquery.prettySocial.js");

require("mapbox.js/dist/mapbox.css")

//import { wcMessenger } from '../js/messenger';
//window.messenger = new wcMessenger();


import "bootstrap"
import "bootstrap/dist/css/bootstrap.css"

import "font-awesome/css/font-awesome.css"

//import swal from "sweetalert2"
//import "sweetalert2/dist/sweetalert2.css"
//import "sweetalert/themes/facebook/facebook.css"

//import "jquery-parallax"

var toastr = require("toastr");
require("toastr/toastr.scss");

window.toastr = toastr; // make available in html
toastr.options = {
    "positionClass": "toast-top-center",
    "closeButton": true
};


  // if (navigator.geolocation) {
  //   console.log("has location")
  //   navigator.geolocation.getCurrentPosition(position => { console.log('in geo') } );
  // } else {
  //   console.log("Geolocation is not supported by this browser.");
  // }


// only used for 2 icons? switched to fa
//require("../fonts/fontello/css/fontello.css")

// will only work from script links in the html
require("../plugins/rs-plugin-5/css/settings.css");
//require("../plugins/rs-plugin-5/css/layers.css");
//require("../plugins/rs-plugin-5/css/navigation.css");


require("../css/animations.css");

// do not appear to be needed
// require("../plugins/owl-carousel/owl.carousel.css");
// require("../plugins/owl-carousel/owl.transitions.css");
// require("../plugins/hover/hover-min.css");

require("../plugins/morphext/morphext.css");

require("../css/style.css");

require("../css/typography-default.css");

require("../css/skins/purple.css");

//require("../css/application.css.scss");
//require("../css/blogs.css.scss");
require("../css/business-search.scss");

require("../css/social-buttons.css");

require("../css/custom.css.scss");

// will only work from script links in the html
// require("../plugins/modernizr.js");
// require("../plugins/rs-plugin-5/js/jquery.themepunch.tools.min.js?rev=5.0");
// require("../plugins/rs-plugin-5/js/jquery.themepunch.revolution.min.js?rev=5.0");

require("../plugins/waypoints/jquery.waypoints.min.js");
require("../plugins/morphext/morphext.min.js");
require("../plugins/jquery.browser.js");
require("../plugins/SmoothScroll.js");
require("../js/template.js");
//require("../js/custom.js");

//require("masonry-layout");
import Masonry from 'masonry-layout';


var ready = function () {
  $('#member-sign-in, #member-sign-in-page, #sign-in-modal').on('ajax:error', function (e) {
    var [data] = e.detail
    if (data.responseText) {
      //toastr.error(data.responseText);
      alertError('Login Error', data.responseText);
      // window.location.replace("/");
    } else {
      //toastr.error('Login error. Please double-check your email and password.');
      alertError('Login Error', 'Please double-check your email and password.');
    }
  }).on('ajax:success', function (e) {
    var [data] = e.detail
    window.location.replace(data.redirect);
  });


  $('#main, #menu').on('ajax:error','.crud-helper', function (e) {
    var [data] = e.detail

    if (data) {
      alertError('Error', data);
    } else {
      alertError('Error');

    }
  }).on('ajax:success','.crud-helper', function (e) {
    var [data] = e.detail

    if (data.success) {
      alertSuccess(data.success)

      if ($(this).data('reset-on-success')) {
        $(this).trigger('reset');
        $(this).find('.missing-value').removeClass('missing-value');
      }
    } else if (data.warning) {
      alertInfo(data.title, data.warning);
    }

    if (data.action) {

      $(data.selector)[data.action](data.value);

      if ((data.selector == '#main') && (data.action == 'html')) {

        let url = data.url || $(this).attr("href");
        history.pushState(data, "", url);
      } else if (data.action == 'append') {
        $(data.selector).children('.default-value').hide();
      }
    }

  });

  $('#main, #menu').on('ajax:before','.crud-validate', function (e) {
    let missing = 0;
    $(this).find('input, textarea').each(function( index ) {
      if ($( this ).prop('required') || $(this).hasClass('required')) {
        if ($( this ).val() == '') {
          $( this ).addClass('missing-value');
          missing++;
        }
      }
    });
    if (missing > 0) {
      alertError('Form Error', 'Please complete missing fields');
      return false;
    }
    return true;
  })

  $('#relation-follow, #relation-friend').on('ajax:error', function (e) {
    var [ data ] = e.detail;
    //toastr.error(data.responseText);
    alertError('Error', data.responseText);
  }).on('ajax:success', function (e) {
    var [data] = e.detail;
    $(this).find('.btn').val(data.buttonText);
  });

  // profile page images
  $('.product_photo').on('click', function(e){
    $('#product_photo_img').attr('src',$(this).data('src')+'/convert?fit=max&h=600&w=600&rotate=exif');
  });

  // blog show
  $('.like')
   .on('ajax:send', function () {
     $('#like_button').attr('src', '/images/leaf-light.png')
   })
   .on('ajax:error', function () {
     $('#wc_like').html(':(');
   })
   .on('ajax:success', function (e) {
     var [data] = e.detail
     $('#wc_like').html(data.count);
     $('#like_button').attr('src',(data.liked=='true' ? '/images/leaf-like-true.png' : '/images/leaf-like-false.png'))
   });


  //blog show
  $('.comment-form')
    .on('ajax:error', function() {
      alertError('Error', 'Error saving comment.');
    })
    .on('ajax:success', function(e) {
      var [data, status, xhr] = e.detail;
      $(this).find('textarea').val('');
      $(xhr.responseText).hide().insertBefore($('#paginate_comments')).show('slow').effect('highlight', {}, 1000);
    });

  // blog show
  $(document).on("ajax:beforeSend", ".comment", function() {
      return $(this).fadeTo('fast', 0.3);
    }).on("ajax:success", ".comment", function() {
      return $(this).hide('fast');
    }).on("ajax:error", ".comment", function() {
      return $(this).fadeTo('fast', 1);
    });


    $('button[data-goto]').on('click', function () {
      window.location = $(this).data('goto');
    })

    $('btn[data-goto]').on('click', function () {
      window.location = $(this).data('goto');
    })



    $('.search-toggle').on('click', function(){
      // set a time out so box can be open before focusing
      setTimeout(function(){
        $("#search-box-input").focus();
      }, 1000);
    });




    // filepicker file upload
  $('.open_add_photo').click(function(e){
    filepicker.pick(function(InkBlob){
  	  $('#photo_url')[0].value=InkBlob.url;
      document.getElementById('new_photo').submit();
     });
   });

   // business profile
   $('.message-btn').on('click', function(){
     //console.log("Here");
    document.getElementById('message').value = '';

    if ($(this).data('business')) {
      var biz_id = $(this).data('business');
      $('.business_ids').each(function(){
        $(this).val(biz_id);
      })
      var recip_name = $(this).data('business-name');
      $('.recipient_names').each(function(){
        $(this).html(recip_name);
      })
    } else {
      document.getElementById('user_id').value = $(this).data('user');
      document.getElementById('recipient-name').innerHTML = $(this).data('user-name');
    }
    if ($(this).data('job')) {
      document.getElementById('job_id').value = $(this).data('job-id');
      document.getElementById('recipient-subject').innerHTML = 'Job: ' + $(this).data('job');
    } else if ($(this).data('wanted')) {
      document.getElementById('wanted_id').value = $(this).data('wanted-id');
      document.getElementById('recipient-subject').innerHTML = 'Wanted: ' + $(this).data('wanted');
    } else if ($(this).data('product')) {
      document.getElementById('product_id').value = $(this).data('product-id');
      document.getElementById('recipient-subject').innerHTML = 'Product: ' + $(this).data('product');
    }
    document.getElementById('sendButton').disabled = false;
    document.getElementById('message').disabled = false;
    document.getElementById('message').placeholder = 'Start typing...';
  });


    // upload action for filepicker
    $( "#main" ).on( "change",'#photo_url', function( event ) {
       Rails.fire(document.getElementById('new_photo'), 'submit')
    });


  // masonry layout for search results pages
  //var $container = $('#mason_col');
  // $('#mason_col').masonry({
  //   itemSelector: '.mason',
  //   isFitWidth: true,
  //   gutter: 15
  // });

  //var elem = document.querySelector('.grid');
  // maybe move into another file?
  var  masonElement = document.getElementById("mason_col")
  if (typeof(masonElement) != 'undefined' && masonElement != null) {
    var msnry = new Masonry( masonElement, {
      itemSelector: '.mason',
      isFitWidth: true,
      gutter: 15
    });
  }


  // Wait before fading out some alerts
  setTimeout(function(){
    $('.alert-notice').slideUp();
  }, 5000);

  setTimeout(function(){
    $('.alert-success').slideUp();
  }, 5000);

  setTimeout(function(){
    $('.alert-warn').slideUp();
  }, 10000);

  $('.tooltip-ready').tooltip();

  // Make textarea fields
  //$('.modal-body #message, .modal-body #product_description, .auto-size').autosize();

  // Override data-dismiss alert closing with a slideUp
  $('.alert').on('close.bs.alert', function(e) {
    e.preventDefault();
    $(this).slideUp();
  })

  //$('.selectpicker').selectpicker({dropupAuto:false,size:'auto'});

  // toggle left side menu
  $('#menutoggle').click(function() {
      //console.log("clicked");
      $('.row-offcanvas').toggleClass('active');
      $('.collapse').toggleClass('in').toggleClass('hidden-xs').toggleClass('visible-xs');
  });

  $('#dashtoggle').click(function() {
      //console.log("clicked");
      $('.side-nav').toggleClass('side-nav-closed');
      //$('.collapse').toggleClass('in').toggleClass('hidden-xs').toggleClass('visible-xs');
  });

  // vanity slug edit

  $('#main').on('keypress','.vanity_input', function (e) {
    var regex = new RegExp("^[a-zA-Z0-9_-]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str) || (e.which == 8) || (e.which == 46)) {
      $('#vanity_path').html($(this)[0].value);
      return true;
    }
    e.preventDefault();
    return false;
  });

  $('#main').on('keyup', '.vanity_input', function (e) {
    $('#vanity_path').html($(this)[0].value);
    if ($(this)[0].value  == '') {
      $('#vanity_root').css('color', '#cccccc');
    } else {
      $('#vanity_root').css('color', '#12a475');
    }
  });

  $('#main').on('click', '.copy-to-clipboard', function (e) {
      document.getElementById($(this).data('clipboard-target')).select();
      document.execCommand('copy');
      toastr.success("Copied to clipboard");
      e.preventDefault();
  });

  $('#main').on('input', '.count1000', function () {
    textCounter($(this), 'ctr', 1000);
  });

  $('#profile_photo').one('error', function() {
    $('#uploads_container').html('<span class="dash_context red">Image processing</span>')
  });

  // $('.profile-photo').one('error', function() {
  //   $(this).closest('.principal-photo').html('<%= image_tag 'logo-default.jpg' %>')
  // });

  $('.no-return-submit').keydown(function(event){
    if(event.keyCode == 13) {
    event.preventDefault();
    return false;
    }
  });

  // Reset password link
  $('.reset-password-link').on('click', function () {
    $('#sign-in-modal').modal('hide');
  });

  $('#main').on('click', '#preview-button', function (e) {
    $('#preview_title').val($('#blog_title').val());
    $('#preview_content').val(CKEDITOR.instances["blog_content"].getData());
    $('#preview_form').submit(function(e) {
        var w = (window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth)*0.80;
        var h = (window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight)*0.90;
        window.open('', 'formpopup', 'width='+w+',height='+h+',resizeable,scrollbars');
        this.target = 'formpopup';
     }).submit();
   });


  $('.tooltip-ready').tooltip();

  // Notes Prompt for Business Applications Accept/Reject UI //
  $('a[data-note]').on('click', function () {
    var acc = $('#acc'+$(this).data('id'));
    var rej = $('#rej'+$(this).data('id'));
    if ($(this).data('note') == 'Remove') {
      acc.attr('href',acc.attr('href').replace(/&note=.*$/,''));
      rej.attr('href',rej.attr('href').replace(/&note=.*$/,''));
      $(this).html('Add a Note').data('note','Add').attr('title','');
      $('.tooltip'+$(this).data('id')).tooltip('destroy');
   } else if ($(this).data('note') == 'Add') {
    var p = prompt($(this).data('msg'));
    if (p) {
      acc.attr('href',acc.attr('href')+'&note='+p);
      rej.attr('href',rej.attr('href')+'&note='+p);
      $(this).html('Remove Note').data('note','Remove').attr('title',p);
      $('.tooltip'+$(this).data('id')).tooltip();
    }
  }
    return false;
  });

  // https://medium.com/store2be-tech/how-to-use-sweetalert2-for-your-rails-5-1-rails-ujs-confirms-without-jquery-8a5b516b2a1
  //Override the default confirm dialog by rails
  // $.rails.allowAction = function(link){
  //   if (link.data("confirm") == undefined){
  //     return true;
  //   }
  //   $.rails.showConfirmationDialog(link);
  //   return false;
  // }
  //
  // //User click confirm button
  // $.rails.confirmed = function(link){
  //   link.data("confirm", null);
  //   link.trigger("click.rails");
  // }

  //Display the confirmation dialog
  // $.rails.showConfirmationDialog = function(link){
  //   swal({
  //     title: link.data("confirm"),
  //     text: link.data("message"),
  //     type: 'warning',
  //     confirmButtonText: 'Yes',
  //     confirmButtonColor: '#9760af',
  //     showCancelButton: true
  //   },function(confirmed){
  //     if (confirmed) {
  //       $.rails.confirmed(link);
  //     } else {
  //       return false;
  //     }
  //
  //  });
  //
  //
  // // ).then(function(e){
  //   //  $.rails.confirmed(link);
  //   // });
  // };

  // $.rails.showConfirmationDialog = function(link){
  //   swal({
  //     title: link.data("confirm"),
  //     text: link.data("message"),
  //     type: 'warning',
  //     confirmButtonText: 'Yes',
  //     confirmButtonColor: '#9760af',
  //     showCancelButton: true
  //   }).then(function () {
  //     $.rails.confirmed(link);
  //   }, function (dismiss) {
  //     if (dismiss === 'cancel') {
  //       return false;
  //     }
  //   }).catch(e => console.log(e));
  // }



  // $('#main').on('ajax:beforeSend', '', function(event, xhr, settings) {
  //   xhr.setRequestHeader('X-Awesome', 'enabled');
  // });
  //$("#select_id option[value='foo']").remove();
//   var x = document.getElementById("mySelect");
// var option = document.createElement("option");
// option.text = "Kiwi";
// x.add(option);

  $(".submit_check").click(function(e){
    if ( $('#user_saved_account_type')[0].value.match(/ plus$/) && $("input[name='user[account_type]']:checked")[0].value == $('#user_saved_account_type')[0].value.replace(' plus','')  ) {
        $("#dialog-confirm").html('Are you sure you want to downgrade your membership?');
		$("#dialog-confirm").dialog({
			resizable: false,
			modal: true,
			title: "Downgrade Your Membership?",
			height: 200,
			width: 300,
			buttons: {
				"Yes": function () {
					$(this).dialog('close');
					$(".edit_user").submit();
				},
					"No": function () {
					$(this).dialog('close');
					return false;
				}
			}
		});
	} else {
	  $(".edit_user").submit();
	}
  });

  $('#h_type').change(function(){
    if (this.value == 'Custom Display') {
      $('#h_text').removeAttr('disabled').focus();
    } else {
      $('#h_text').val('').attr('disabled', 'disabled');
    }
  });

  $('#main').on('input', '#edit_business_name', () => {
    if ($('#edit_business_name').val() == 'My Business Name') {
      $('#save_business_button').attr('disabled', true);
      $('#save_business_button').attr('title', 'You must first change your business name.');
    } else {
      $('#save_business_button').removeAttr('disabled');
      $('#save_business_button').attr('title', 'Publish your business profile.');
    }
  });





} // end ready

$(document).ready(ready);

// reload the content when back or forward buttons are pressed in browser
window.addEventListener('popstate', function(e) {
  let data = e.state;
  //console.log("call popstate");
  if (data && data.action) {
    //console.log("Refresh page: popstate");
    $(data.selector)[data.action](data.value);
  }
});


window.copyToClipboard = function (e, elID) {
  $('#cb_' + elID).collapse("show");
  document.getElementById("url_"+elID).select();
  document.execCommand('copy');
  toastr.success("Copied to clipboard");
  e.preventDefault();
}

// filepicker helper
window.addFilenameRemoveImg = function (event, name_target, img_target) {
  var image = document.getElementById(img_target);
  image.innerHTML = '';
  var filelabel = document.getElementById(name_target);
  filelabel.innerHTML = event.fpfile.filename + ' (unsaved)';
}


function textCounter(field, cnt, maxlimit) {
  var cntfield = document.getElementById(cnt)
  if (field.val().length > maxlimit) {
    field.val(field.val().substring(0, maxlimit));
  } else {
    cntfield.innerHTML = maxlimit - field.val().length;
  }
}

window.alertSuccess = (title, message) => {
  swal({
    title: title,
    html: message,
    timer: 4000,
    showConfirmButton: true,
    confirmButtonColor: '#9760af',
    type: 'success'
  }).catch(swal.noop);
}

window.alertInfo = (title, message) => {
  swal({
    title: title,
    html: message,
    timer: 4000,
    showConfirmButton: true,
    confirmButtonColor: '#9760af',
    type: 'warning'
  }).catch(swal.noop);
}

window.alertError = (title, message) => {
  swal({
    title: title,
    html: message,
    timer: 10000,
    showConfirmButton: true,
    confirmButtonColor: '#9760af',
    type: 'error',
  }).catch(swal.noop);
}

var addImageInDiv = function (event, name_target) {
  var filelabel = document.getElementById(name_target);
  filelabel.innerHTML = "<img src='"+event.fpfile.url+"'>";
}

var addImageInDivQs = function (event, name_target, qs) {
  var filelabel = document.getElementById(name_target);
  filelabel.innerHTML = "<img src='"+event.fpfile.url+'/convert?'+qs+"' style='border-radius:inherit;'>";
}

var addFileUrl = function (event, name_target) {
  var filelabel = document.getElementById(name_target);
  filelabel.innerHTML = event.fpfile.url + ' (unsaved)'
}

function js_rails_flash(alert_type, alert_msg, alert_ms) {
  $('#js_alert_msg').html(alert_msg);
  $('#js_alert').addClass('alert-'+alert_type).removeClass('hidden').fadeIn().delay(alert_ms).fadeOut();
}

// window.navSetActive = () => {
//   console.log(document.location.href);
//   $('.nav-set-active').each(function(index) {
//     $( this ).find('li').each((liIndex, el) => {
//       let navLink = $(el).find('a');
//       if (navLink.attr('href') == location.pathname) {
//         $(el).addClass('active');
//       } else {
//         $(el).removeClass('active');
//       }
//     })
//   })
// }
